<template>
  <div v-padding:top="46">
    <van-nav-bar title="其他补充材料" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          name="name"
          v-model="formData.name"
          label="文件名称"
          placeholder="请填写文件名称"
          :rules="[{ required: true, message: '请填写文件名称' }]"
        />
        <van-field name="uploader" label="附件">
          <template #input>
            <van-uploader
              v-model="file"
              :max-count="1"
              :max-size="10240000"
              :after-read="afterRead"
              :before-read="beforeRead"
              @delete="deleteFile"
            />
          </template>
        </van-field>
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        name: "",
      },
      file: [],
      uploadedFile: [],
      fileType: [],
      submitLoading: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.operate == "edit") {
      this.getSupplementaryInfo();
    }
  },
  methods: {
    beforeRead(file) {
      const flag = new RegExp(
        "[`~!@#$^&*=|{}':;',\\[\\]<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？%]"
      );
      if (flag.test(file.name)) {
        Toast.fail({
          message: "文件名不能包含特殊字符",
        });
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.fileType = this.fileType.concat(file.file.name);
      file.status = "uploading";
      const data = new FormData();
      data.append("file", file.file);
      this.$api.setting.uploadFile(data).then((res) => {
        this.uploadedFile = this.uploadedFile.concat(res.data.data[0].id);
        file.status = "done";
      });
    },
    deleteFile(file, detail) {
      file.status = "uploading";
      const { index } = detail;
      const fileId = this.uploadedFile[index];
      const typeId = this.fileType[index];
      const data = {
        id: fileId,
      };
      this.$api.common.getDeleteFile(data).then((res) => {
        if (res.data.state == 0) {
          this.uploadedFile = this.uploadedFile.filter((item) => {
            return item != fileId;
          });
          this.fileType = this.fileType.filter((item) => {
            return item != typeId;
          });
          file.status = "done";
        }
      });
    },
    // 新增其他补充材料
    addSupplementary() {
      this.submitLoading = true;
      const data = {
        enterpriseId: this.enterpriseId,
        name: this.formData.name,
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .addSysOtherSupplementaryMaterials(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 修改其他补充材料
    editSupplementary() {
      this.submitLoading = true;
      const data = {
        enterpriseId: this.enterpriseId,
        id: this.formData.id,
        name: this.formData.name,
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .posteditSysOtherSupplementaryMaterials(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    onSubmit() {
      if (this.$route.query.operate == "add") {
        this.addSupplementary();
      } else if (this.$route.query.operate == "edit") {
        this.editSupplementary();
      }
    },
    // 日历确定
    onConfirm(date) {
      this.formData.approvalTime = this.$moment(date).format("YYYY-MM-DD");
      this.calendarShow = false;
    },
    // 获取其他补充材料详情
    getSupplementaryInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting
        .getSysOtherSupplementaryMaterialsById(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.formData = {
              id: res.data.data.id,
              name: res.data.data.name,
            };
            if (res.data.data.fileVoList.length > 0) {
              const tempArr = [];
              const tempFileIds = [];
              const fileArr = res.data.data.fileVoList || [];
              fileArr.forEach((element) => {
                tempArr.push({
                  name: element.fileName,
                  url: element.url,
                  id: element.id,
                });
                tempFileIds.push(element.id);
              });
              this.file = tempArr;
              this.uploadedFile = tempFileIds;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
